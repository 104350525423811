<template>
  <div class="reservation-discount">
    <p class="title">活動折抵</p>
    <el-form ref="formRef" v-loading="loading" :model="formData" :rules="formRules">
      <el-form-item prop="cashbackDiscount" :error="inputErrorMsg">
        <div>
          <p class="font-medium text-normal">回饋金</p>
          <p class="text-gray-60 text-sm">此筆預約訂單可折抵上限為 {{ displayData.discountLimit }} 元，目前會員持有回饋金 {{ displayData.memberCashbackBalance }} 元。</p>
          <el-input v-model="formData.cashbackDiscount" @input="onInputBlur" />
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { computed, defineComponent, nextTick, onMounted, reactive, ref, watch } from 'vue'
import store from '@/store'
import storeMutations from '@/store/reservationCreate/mutations'
import { get, toNumber } from 'lodash'
import { integerRules, isDigitRules, rangeRules } from '@/validation'
import reservationMixin from '@/mixin/reservation'
import formUtils from '@/utils/form'

export default defineComponent({
  name: 'ReservationDiscount',
  mixins: [reservationMixin],
  setup (props, { emit }) {
    const loading = computed(() => get(store.state, 'reservationCreate.loading.cashbackDiscount', false))
    const inputErrorMsg = ref('')
    const formRef = ref(null)
    const formData = reactive({
      cashbackDiscount: 0,
    })
    const limitDiscount = computed(() => get(store.state, 'reservationCreate.orderDiscount.limit', 0))
    const canEffortMax = computed(() => {
      const balance = get(store.state, 'reservationCreate.memberData.cashbackWallet.balance', 0)
      if (balance >= limitDiscount.value) return limitDiscount.value
      return balance
    })
    const formRules = computed(() => {
      return {
        cashbackDiscount: [isDigitRules(), integerRules(), rangeRules(0, canEffortMax.value)],
      }
    })
    const displayData = computed(() => {
      return {
        discountLimit: get(store.state, 'reservationCreate.orderDiscount.limit', '-'),
        memberCashbackBalance: get(store.state, 'reservationCreate.memberData.cashbackWallet.balance', '-'),
      }
    })

    const lastInputTime = ref(null)

    const onInputBlur = () => {
      const now = new Date()
      lastInputTime.value = now
      setTimeout(() => {
        const delayNow = new Date()
        if (delayNow - lastInputTime.value < 500) return

        if (formData.cashbackDiscount > limitDiscount.value) {
          formUtils.clearValidate(formRef.value, ['cashbackDiscount'])
          inputErrorMsg.value = ''
          setTimeout(() => {
            inputErrorMsg.value = `回饋金折抵上限為 ${limitDiscount.value} 元`
          }, 100)
          return
        }
        if (formData.cashbackDiscount && !Number.isInteger(Number(formData.cashbackDiscount))) {
          formUtils.clearValidate(formRef.value, ['cashbackDiscount'])
          inputErrorMsg.value = ''
          setTimeout(() => {
            inputErrorMsg.value = '請輸入整數'
          }, 100)
          return
        }
        formUtils.clearValidate(formRef.value, ['cashbackDiscount'])
        inputErrorMsg.value = ''
        emit('change')
      }, 500)
    }

    watch(formData, () => {
      store.commit(`reservationCreate/${storeMutations.setStateData}`, { key: 'orderDiscount.cashback', value: toNumber(formData.cashbackDiscount) })
    })

    onMounted(async () => {
      await nextTick()
      console.log('csacsacsa')
      store.commit(`reservationCreate/${storeMutations.setFormRef}`, { key: 'cashbackDiscount', ref: formRef.value })
    })
    return { displayData, loading, formRules, formData, formRef, onInputBlur, inputErrorMsg }
  },
})
</script>

<style lang="postcss" scoped>
.reservation-discount {
  @apply bg-[#F5F7FA] p-[20px] rounded-[8px];
}
</style>
