import configuration from '@/configuration'
import { checkUserFeature } from '@/store/modules/permission'
import { get } from 'lodash'
import store from '@/store'

export const checkAction = (action) => {
  const originShopPlanFeatures = get(store.state, 'permission.raw.shopPlanFeatures')
  const originShopUserFeatures = get(store.state, 'permission.raw.userFeatures')
  const computedShopPlanFeatures = get(store.state, 'permission.computed.shopPlanFeatures')
  const computedShopUserFeatures = get(store.state, 'permission.computed.userFeatures')

  if (!action) return false
  if (configuration('featureKeyDebugger')) {
    return checkUserFeature(computedShopPlanFeatures, computedShopUserFeatures, action)
  }
  return checkUserFeature(originShopPlanFeatures, originShopUserFeatures, action)
}
