<template>
  <section class="deposit-block">
    <p class="title">選擇付款方式</p>
    <el-form label-position="top">
      <el-form-item label="結帳明細">
        <ReservationCheckoutReceipt :data="orderCheckout.data" />
      </el-form-item>
      <el-form-item label="實付金額">
        <span>$ {{ orderCheckout.price }}</span>
        <!-- <span v-if="enableClassTicketCredit && isUseClassTicket"> ( 堂票已折抵訂金 )</span> -->
      </el-form-item>
      <el-form-item label="付款方式">
        <el-radio-group v-model="syncModel">
          <div class="flex flex-col" style="gap: 12px; margin-top: 12px">
            <el-radio
              v-for="payment in paymentOptions"
              :key="payment.value"
              :label="payment.value"
              :disabled="disabledWallet(payment.value)"
            >
              <span v-if="payment.value === 'wallet'">{{ payment.name }}（本次使用：${{ orderCheckout.price }} / 使用後餘額：${{
                walletBalance - orderCheckout.price
              }}）</span>
              <span v-else>{{ payment.name }}</span>
            </el-radio>
          </div>
        </el-radio-group>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
import { defineComponent, computed, ref } from 'vue'
import { depositPaymentTypes, staticDepositPaymentTypes } from '@/config/deposit'
import { FindMember } from '@/api/member'
import { map, get } from 'lodash'
import { mapGetters } from 'vuex'
import ReservationCheckoutReceipt from '@/components/Reservation/ReservationCheckoutReceipt/ReservationCheckoutReceipt.vue'

export default defineComponent({
  name: 'ReservationCheckoutPayment',
  components: {
    ReservationCheckoutReceipt,
  },
  props: {
    orderCheckout: {
      type: Object,
      default: () => ({}),
    },
    model: String,
    memberData: {
      type: Object,
      defauilt: () => ({}),
    },
  },
  setup (props, { emit }) {
    const member = ref({})
    const formRef = ref(null)
    // const enableClassTicketCredit = computed(() => get(props.configData, 'enableClassTicketCredit'))
    const memberId = computed(() => {
      return get(props.memberData, 'member.id')
    })

    const walletBalance = computed(() => {
      return get(member.value, 'Wallet.balance') || 0
    })
    return {
      formRef,
      member,
      //  enableClassTicketCredit,
      memberId,
      walletBalance,
    }
  },
  data: () => ({
    payment: '',
  }),
  computed: {
    ...mapGetters(['shop']),
    paymentOptions () {
      // const options = this.paymentConfig.map(i => depositPaymentTypes[i.type])
      const options = map(depositPaymentTypes, (i) => ({
        name: i.name,
        value: i.value,
        type: i.type,
      }))
      if (!this.orderCheckout.price) return [{ name: '免付款', value: 'free' }]
      if (!this.memberData.isMember) return options.filter((i) => i.type === 'offline')
      return options.filter((i) => i.type === 'offline' || i.type === 'wallet')
    },

    syncModel: {
      get () {
        return this.model
      },
      set (val) {
        return this.$emit('update:model', val)
      },
    },
  },
  watch: {
    paymentOptions () {
      if (!this.orderCheckout.price) this.syncModel = 'free'
      else this.syncModel = this.paymentOptions[0].value
    },
    async memberId (id) {
      await this.findMember(id)
      this.defaultPayment()
    },
  },
  async mounted () {
    await this.findMember(this.memberId)
    this.defaultPayment()
  },
  methods: {
    disabledWallet (type) {
      if (type === 'wallet' && this.walletBalance === 0) return true
      const left = this.walletBalance - this.orderCheckout.price
      if (type === 'wallet' && left < 0) return true
      return false
    },
    async findMember (id) {
      if (!id) return
      const res = await FindMember({
        shopId: this.shop,
        id,
      })
      this.member = res
    },
    defaultPayment () {
      if (!this.orderCheckout.price) this.syncModel = 'free'
      else {
        if (this.paymentOptions.length) {
          if (this.disabledWallet('wallet')) {
            const options = this.paymentOptions.filter((i) => i.value !== 'wallet')
            if (options.length) this.syncModel = options[0].value
          } else {
            this.syncModel = this.paymentOptions[0].value
          }
        }
      }
    },
  },
})
</script>

<style lang="postcss" scoped>
.title {
  @apply text-primary-100 font-bold text-[18px] pb-[24px];
}

.deposit-block {
  @apply bg-[#F5F7FA] p-[20px] rounded-[8px];
}
</style>
