<template>
  <el-form v-if="isShow" ref="formRef" :model="formData" label-position="top">
    <div class="flex flex-col" style="gap: 24px">
      <SelectServiceClassTicket
        title="主要服務"
        propName="mainService"
        :required="isRequired(mainServiceData.serviceDetail)"
        :service="mainServiceData.serviceDetail"
        :memberClassTickets="memberClassTickets"
        :classTicket.sync="syncMainServiceData.classTicket"
        :reservationTime="reservationTime(syncMainServiceData.time)"
      />
      <SelectServiceClassTicket
        v-for="(data, index) in otherServiceData"
        :key="get(data.service, 'id')"
        :propName="`otherService-${index}`"
        title="其他服務"
        :service="data.serviceDetail"
        :required="isRequired(data.service)"
        :memberClassTickets="memberClassTickets"
        :classTicket.sync="syncOtherServiceData[index].classTicket"
        :reservationTime="reservationTime(syncOtherServiceData[index].time)"
      />
    </div>
  </el-form>
</template>

<script>
import { defineComponent, ref, computed, onMounted, nextTick, reactive, watch } from 'vue'
import { useVModel } from '@vueuse/core'
import SelectServiceClassTicket from './SelectServiceClassTicket.vue'
import { get, isEmpty } from 'lodash'
import formUtils from '@/utils/form'
import store from '@/store'
import { dayjs } from '@/lib/dayjs'
import { formatDate } from '@/utils/date'

export default defineComponent({
  name: 'ServiceClassTicket',
  components: { SelectServiceClassTicket },
  props: {
    mainServiceData: { type: Object, default: () => ({}) },
    otherServiceData: { type: Array, default: () => ([]) },
  },
  emits: ['ref'],
  setup (props, { emit }) {
    const syncMainServiceData = useVModel(props, 'mainServiceData', emit)
    const syncOtherServiceData = useVModel(props, 'otherServiceData', emit)
    const memberClassTickets = computed(() => get(store.state, 'reservationCreate.memberClassTickets', []))
    const reservationTime = computed(() => {
      return (period) => {
        const date = formatDate(get(syncMainServiceData.value, 'date'), 'YYYY/MM/DD')
        return `${date} ${period}`
      }
    })
    const formRef = ref(null)
    const formData = reactive({
      mainService: computed(() => get(syncMainServiceData.value, 'classTicket')),
      'otherService-0': computed(() => get(syncOtherServiceData.value, '[0].classTicket')),
    })
    const isShow = computed(() => {
      const mainService = get(props.mainServiceData, 'serviceDetail')
      if (get(mainService, 'requiredClassTicket')) return true
      return !isEmpty(props.mainServiceData) || (!isEmpty(props.otherServiceData) && !isEmpty(memberClassTickets.value))
    })

    const isRequired = (service) => {
      return (get(service, 'requiredClassTicket') && get(service, 'enableClassTicket'))
    }

    watch(() => get(props.syncMainServiceData, 'serviceDetail'), () => {
      formUtils.clearValidate(formRef.value)
    })

    onMounted(async () => {
      await nextTick()
      emit('ref', formRef.value)
    })

    return {
      syncMainServiceData,
      syncOtherServiceData,
      formRef,
      formData,
      isRequired,
      get,
      isShow,
      memberClassTickets,
      reservationTime,
    }
  },
})

</script>
