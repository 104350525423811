<template>
  <el-form ref="form" :model="formData" :rules="formRules" label-position="top">
    <el-form-item label="會員類別" prop="isMember">
      <el-radio-group v-model="formData.isMember" @change="changeIsMember">
        <el-radio data-cy="is-member-radio-btn" :label="true">會員</el-radio>
        <el-radio data-cy="not-member-radio-btn" :label="false">非會員</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item v-if="config.reservation.allowOrderMorePeople" label="預約人數">
      <template slot="label">
        <div class="flex items-center" style="gap: 8px">
          <p>預約人數 <span v-if="checkAction('admin.resourceItem.page')" class="text-sub text-gray-60">(超過 1 人的預約，僅提供「純人員服務」)</span></p>
        </div>
      </template>
      <el-input-number
        v-model="formData.peopleCount"
        data-cy="order-people-num-input"
        :min="1"
        :max="config.reservation.maxOrderMorePeopleCount"
      />
    </el-form-item>
    <el-form-item v-if="formData.isMember" label="會員" prop="member">
      <MemberSearch
        data-cy="member-select"
        :model.sync="formData.member"
        @change="onMemberChange"
      />
    </el-form-item>

    <div v-if="!formData.isMember">
      <el-form-item label="姓名" prop="name">
        <el-input v-model="formData.name" data-cy="user-name" clearable placeholder="請輸入姓名" />
      </el-form-item>
      <el-form-item label="電話號碼" prop="phone">
        <el-input v-model="formData.phone" data-cy="user-phone" clearable placeholder="請輸入電話號碼" />
      </el-form-item>
    </div>
  </el-form>
</template>

<script>
import MemberSearch from '@/components/Search/MemberSearch.vue'
import { noEmptyRules, phoneRules } from '@/validation'
import { FindMember } from '@/api/member'
import { get, isEmpty } from 'lodash'
import { useVModel } from '@vueuse/core'
import { usePermissions } from '@/use/permissions'
import store from '@/store'
import { useRoute } from 'vue-router/composables'
import { computed, onMounted, reactive, ref, watch, nextTick } from 'vue'
import { useShop } from '@/use/shop'

export default {
  name: 'MemberInfo',
  components: {
    MemberSearch,
  },
  props: {
    config: {
      type: Object,
      default: () => ({
        allowOtherReservation: false,
      }),
    },
    memberClassTickets: {
      type: Array,
      default: () => ([]),
    },
    reservation: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const route = useRoute()
    const fixedMember = computed(() => route.query.member)
    const { checkAction } = usePermissions()
    const form = ref(null)
    const formData = reactive({
      isMember: true,
      member: null,
      peopleCount: 1,
      name: null,
      phone: null,
    })
    const formRules = reactive({
      isMember: [noEmptyRules()],
      member: [noEmptyRules()],
      name: [noEmptyRules()],
      phone: [noEmptyRules(), phoneRules()],
    })
    watch(() => props.reservation, async () => {
      await syncData()
    })
    watch(formData, (data) => {
      emit('update', data)
    }, { deep: true })

    const syncData = async () => {
      if (!isEmpty(props.reservation)) {
        const member = get(props.reservation, 'Member')
        const memberPhone = get(member, 'UserInfo.phone')
        const memberName = get(member, 'UserInfo.name')
        const userName = get(props.reservation, 'userName')
        const userPhone = get(props.reservation, 'userPhone')
        if(!member || memberPhone !== userPhone || memberName !== userName) {
          formData.isMember = false
          formData.name = userName
          formData.phone = userPhone
        }
      }
    }
    const onMemberChange = async (member) => {
      if (!member) return
      console.log('🚩onMemberChange')
      await store.dispatch('reservationCreate/getMebmerData', member.id)
      console.log('🚩getMebmerData', store.state.reservationCreate.memberData)
      emit('memberChange', store.state.reservationCreate.memberData)
    }
    const findMember = async (memberId) => {
      if (!memberId) return
      try {
        const member = await FindMember({
          shopId: shopId.value,
          id: memberId,
        })
        formData.member = member
      } catch (e) {
        window.$message.error(e.message)
      }
    }
    const changeIsMember = (isMember) => {
      if (!isMember) formData.member = null
      if (isMember) {
        formData.name = null
        formData.phone = null
      }
    }
    onMounted(async () => {
      await nextTick()
      emit('ref', form.value)
      if (fixedMember.value) {
        console.log('🚩fixedMember')
        await findMember(fixedMember.value)
        onMemberChange(formData.member)
        store.dispatch('reservationCreate/getMebmerData', fixedMember.value)
      }
      await syncData()
    })

    return { onMemberChange, checkAction, fixedMember, formData, formRules, changeIsMember, form }
  },
}
</script>

<style scoped lang="postcss">
</style>
