import { CalculateCheckoutCashbackLimit } from '@/api/reservation'
import { FindMemberRebateWallet } from '@/api/rebate'
import { FindMember } from '@/api/member'
import { GetClassTicketRecord2 } from '@/api/classTicket'

import { useFetch } from '@/use/fetch'
import { checkAction } from '@/utils/permissions'

// others
import { OrderDeposit } from '@wishcbg/bob-appointment-deposit-package'
import { set, get, map, forEach, pick, filter } from 'lodash'
import dayjs from '@/lib/dayjs'
import mutationTypes from './mutations'
import formUtils from '@/utils/form'
import Vue from 'vue'

const { fetchAdapter, fetchAll } = useFetch()

const state = {
  ready: false,
  configData: {},
  peopleCount: 1,
  mainServiceData: {
    service: null,
    attachServices: [],
    subService: null,
    classTicket: null,
    date: null,
    time: null,
    serviceUnit: null,
  },
  otherServiceData: [],
  otherUserData: [],
  memberData: {},
  memberClassTickets: [],
  forms: {
    userInfo: null, // 人數、姓名、手機
    mainService: null, // 主服務
    otherService: null, // 其他服務
    payment: null, // 付款方式
    discount: null, // 折扣資訊
    note: null, // 備註
    invoice: null, // 發票
  },

  // 訂單折抵資料
  orderDiscount: {
    limit: 0,
    cashback: 0,
  },
  // 發票資料
  invoiceData: {},
  // 訂單結帳結果資料
  orderCheckout: {
    price: 0,
  },
  // 訂單訂金結果資料
  orderDeposit: {
    need: false,
    price: 0,
  },
  loading: {
    classTicket: false,
    cashbackDiscount: false,
    payment: false,
    submitBtn: false,
  },
}

const mutations = {
  [mutationTypes.setStateData]: (state, { key, value }) => {
    set(state, key, value)
  },
  [mutationTypes.setFormRef]: (state, { key, ref }) => {
    set(state.forms, key, ref)
  },
  [mutationTypes.uppdateMemberClassTickets]: (state, value) => {
    Vue.set(state, 'memberClassTickets', value)
  },
  [mutationTypes.clearMainServiceData]: (state, value) => {
    Vue.set(state, 'memberClassTickets', value)
  },
}

const actions = {
  checkOrderDeposit: ({ state, getters }, depositConfig) => {
    const orderDeposit = new OrderDeposit(getters.AppointmentOrder, depositConfig)

    console.log('計算訂金', {
      need: orderDeposit.needDeposit(),
      price: orderDeposit.getDepositPrice() || 0,
    }, getters.AppointmentOrder)

    state.orderDeposit = {
      need: orderDeposit.needDeposit(),
      price: orderDeposit.getDepositPrice() || 0,
    }
  },

  checkOrderCashbackLimit: async ({ state, getters }, { shopId, memberId, reservations }) => {
    state.loading.cashbackDiscount = true
    const [res, err] = await CalculateCheckoutCashbackLimit({
      shopId,
      memberId,
      reservations: filter(map(reservations, (item) => {
        return {
          ...pick(item, [
            'appointmentServiceId',
            'appointmentSubServiceId',
            'appointmentServiceAttachIds',
          ]),
          useClassTicketRecordId: item.classTicketRecordId || null,
        }
      }), (item) => item.appointmentServiceId),
    })
    state.loading.cashbackDiscount = false
    if (err) {
      window.$message.error(err)
      return
    }
    state.orderDiscount.limit = res.limit
  },

  // 取得會員資料
  getMebmerData: async ({ state, rootGetters }, memberId) => {
    const callList = []

    // callList.push(async () => {
    const [res, err] = await fetchAdapter(FindMember, {
      shopId: rootGetters.shop,
      id: memberId,
    }, { version: 'v1' })
    if (err) {
      window.$message.error(err)
      return
    }
    state.memberData = res
    // })

    // 如果有啟用結帳回饋金折抵
    if (get(state.configData, 'checkout.enableCashbackDeduction')) {
      if (checkAction('admin.shopCashback.page') && get(state.configData, 'checkout.enableCashbackDeduction')) {
        callList.push(async () => {
          // 取得會員回饋金錢包
          const [res, err] = await FindMemberRebateWallet({
            shopId: rootGetters.shop,
            memberId,
          })
          if (err) {
            window.$message.error(err)
            return
          }
          set(state, 'memberData.cashbackWallet', res)
        })
      }
    }

    // promise all callList
    await Promise.all(callList.map((call) => call()))
  },

  validateForms: async ({ state }) => {
    for (const formKey of Object.keys(state.forms)) {
      if (state.forms[formKey]) {
        if (!(await formUtils.checkForm(state.forms[formKey]))) return false
      }
    }
    return true
  },
}

const getters = {
  //! 尚未重構完成
  AppointmentOrder: (state) => {
    const main = {
      AppointmentService: {
        id: get(state.mainServiceData, 'service.id'),
        price: get(state.mainServiceData, 'service.price'),
      },
      AppointmentServiceAttaches: map(get(state.mainServiceData, 'attachServices'), i => ({
        id: i.id,
        price: i.price,
      })),
      isUseClassTicket: !!get(state.mainServiceData, 'classTicket.id'),
    }

    // 如果有選擇子服務
    if (get(state.mainServiceData, 'subService')) {
      main.AppointmentService.id = get(state.mainServiceData, 'subService.id')
      main.AppointmentService.price = get(state.mainServiceData, 'subService.price')
    }

    const others = []
    // 單人多服務
    if (state.peopleCount === 1 && this.canOrderOtherService) {
      forEach(this.otherServiceData, other => {
        let serviceId = get(other, 'service.id')
        let servicePrice = get(other, 'service.price')

        if (other.subService) {
          serviceId = get(other, 'subService.id')
          servicePrice = get(other, 'subService.price')
        }

        others.push({
          AppointmentService: {
            id: serviceId,
            price: servicePrice,
          },
          AppointmentServiceAttaches: map(other.attachServices, i => ({
            id: i.id,
            price: i.price,
          })),
          isUseClassTicket: !!get(other, 'classTicket.id'),
        })
      })
    }
    // 多人單服務
    if (state.peopleCount > 1 && this.config.allowOrderMorePeople) {
      for (let count = 0; count < state.peopleCount - 1; count++) {
        const data = {
          AppointmentService: {
            id: get(state.mainServiceData, 'service.id'),
            price: get(state.mainServiceData, 'service.price'),
          },
          AppointmentServiceAttaches: map(get(state.mainServiceData, 'attachServices'), i => ({
            id: i.id,
            price: i.price,
          })),
        }
        if (get(state.mainServiceData, 'subService')) {
          data.AppointmentService.id = get(state.mainServiceData, 'subService.id')
          data.AppointmentService.price = get(state.mainServiceData, 'subService.price')
        }
        others.push(data)
      }
    }

    const date = dayjs(state.mainServiceData.date).format('YYYY-MM-DD')
    // let time = dayjs(state.mainServiceData.time).format('HH:mm')
    // if (isInvalidDate(time)) time = state.mainServiceData.time
    const time = state.mainServiceData.time || '00:00'
    const start = dayjs(`${date} ${time}`).toDate()

    const base = {
      start,
      AppointmentReservations: [],
    }

    // member
    const isMember = get(state.memberData, 'isMember')
    if (isMember) {
      const member = get(state.memberData, 'member')
      if (member) {
        base.Member = {
          id: member.id,
          MTags: map(member.MTags, tag => ({ id: tag.id })),
          SystemMTag: map(member.SystemMTag, tag => ({ id: tag.id })),
        }
      }
    }

    if (!others.length) {
      base.AppointmentReservations = [main]
      return base
    }

    base.AppointmentReservations = [main, ...others]
    return base
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
